body.locked,
body.locked #root,
body.locked #root__body {
  max-height: 100vh;
  overflow: hidden;
}

#root {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr;
  min-height: 100vh;
}

#root__body {
  position: relative;
}
